import { http } from '../http-common';
import { StoreModel } from './../../models/Store';

class StoreService {
  store: StoreModel = new StoreModel()

  getAll () {
    return http.api.get('/store');
  }

  create (data: StoreModel) {
    return http.api.post('/store', data);
  }

  updateContact (data: StoreModel) {
    if (data.phone?.includes(',')) {
      const arr = []
      let phone = ''
      for (let i = 0; i < data.phone.length; i++) {
        const number = data.phone[i];
        if (number === ',') {
          arr.push(phone.trim())
          phone = ''
        } else {
          phone += number
        }

        if (i === data.phone.length - 1) {
          arr.push(phone.trim())
        }
      }
      data.phone = arr
    }

    if (data.workingTime?.includes(',')) {
      const arr = []
      let workingTime = ''
      for (let i = 0; i < data.workingTime.length; i++) {
        const number = data.workingTime[i];
        if (number === ',') {
          arr.push(workingTime.trim())
          workingTime = ''
        } else {
          workingTime += number
        }

        if (i === data.workingTime.length - 1) {
          arr.push(workingTime.trim())
        }
      }
      data.workingTime = arr
    }
    return http.api.post('/store/contact', data);
  }

  updateInfo (data: StoreModel) {
    return http.api.post('/store/info', data);
  }

  delete (id: string) {
    return http.api.delete(`/store/${id}`);
  }
}

export default new StoreService();
