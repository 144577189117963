
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { mapState } from 'vuex';
import { STORE_NAME } from './core/variables';
import Footer from './layout/Footer.vue';
import Navbar from './layout/Navbar.vue';
import NavbarHome from './layout/NavbarHome.vue';

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    NavbarHome,
    Footer
  },
  computed: mapState({
    isHome: () => {
      const router = useRouter()
      const name = router.currentRoute.value.name
      return name === 'home' || !name
    }
  }),
  mounted () {
    this.addClass()
  },
  methods: {
    addClass () {
      document.addEventListener('DOMContentLoaded', function (event) {
        document.addEventListener('scroll', function (event) {
          const animatedBoxes = document.getElementsByClassName('animated-box');
          const windowOffsetTop = window.innerHeight + window.scrollY;

          Array.prototype.forEach.call(animatedBoxes, (animatedBox) => {
            const animatedBoxOffsetTop = animatedBox.offsetTop;

            if (windowOffsetTop >= animatedBoxOffsetTop) {
              const arrayClasses = animatedBox.className.split(' ');
              if (arrayClasses.indexOf('fade-in') === -1) {
                animatedBox.className += ' ' + 'fade-in';
              }
            }
          });
        });
      });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = to.meta.title || STORE_NAME;
      }
    }
  }
})
