import { http } from '../http-common';

class FileService {
  getAll () {
    return http.api.get('/file');
  }

  getView (id: string) {
    return http.api.get(`/file/${id}/view`);
  }

  create (data: File) {
    const fd = new FormData()
    fd.append('file', data)
    return http.api.post('/file', fd, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  delete (id: string) {
    return http.api.delete(`/file/${id}`);
  }
}

export default new FileService();
