export class StoreModel {
  id?: string
  name!: string
  address!: string
  phone?: string[]
  slogan?: string
  introduce?: string
  mapLocation?: string
  about?: string
  brandLogo?: string
  brandName?: string
  email?: string
  workingTime?: string[]
  // workingTime?: {
  //   date?: string
  //   time?: string
  // }[]
}
