
import store from '@/store/index';
import { defineComponent } from 'vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import { mapState } from 'vuex';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'SlideView',
  components: {
    Carousel,
    Slide,
    Navigation
  },
  computed: mapState({ products: () => store.state.products }),
  mounted () { this.getAll() },
  methods: {
    getAll () {
      store.dispatch('loadProducts');
    }
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center'
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: 'center'
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start'
      }
    }
  })
})
