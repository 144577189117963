
import store from '@/store/index';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'NavbarComponent',
  computed: mapState({ storeModel: () => store.state.storeModel }),
  mounted () { this.getAll() },
  methods: {
    getAll () {
      store.dispatch('loadStore');
    }
  }
})
