import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarHome = _resolveComponent("NavbarHome")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isHome)
      ? (_openBlock(), _createBlock(_component_NavbarHome, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isHome)
      ? (_openBlock(), _createBlock(_component_Navbar, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer)
  ], 64))
}