import { Category } from '@/models/Category';
import { http } from '../http-common';

class CategoryService {
  getAll () {
    return http.api.get('/category');
  }

  create (data: Category) {
    return http.api.post('/category', data);
  }

  update (data: Category) {
    return http.api.put(`/category/${data._id}`, data);
  }

  delete (id: string) {
    return http.api.delete(`/category/${id}`);
  }
}

export default new CategoryService();
