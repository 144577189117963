
import store from '@/store/index';
import { mapState } from 'vuex';
import SlideView from './Slide.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    SlideView
  },
  // data: () => {
  //   return {
  //     categories: getCategories()
  //   }
  // },
  computed: mapState({ categories: () => store.state.categories }),
  mounted () { this.getAll() },
  methods: {
    getAll () {
      store.dispatch('loadCategories');
    }
  }
})
