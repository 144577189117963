import { Product } from '@/models/Product';
import { http } from '../http-common';

class ProductService {
  getAll () {
    return http.api.get('/product');
  }

  create (data: Product) {
    return http.api.post('/product', data);
  }

  update (data: Product) {
    return http.api.put(`/product/${data._id}`, data);
  }

  delete (id: string) {
    return http.api.delete(`/product/${id}`);
  }
}

export default new ProductService();
