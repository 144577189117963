import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gioi-thieu',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/san-pham',
    name: 'menu',
    component: () => import('../views/MenuView.vue')
  },
  {
    path: '/lien-he',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },

  // ADMIN
  {
    path: '/admin/store',
    name: 'store',
    component: () => import('../admin/store/StorePage.vue')
  },
  {
    path: '/admin/category',
    name: 'category',
    component: () => import('../admin/category/CategoryPage.vue')
  },
  {
    path: '/admin/product',
    name: 'product',
    component: () => import('../admin/product/ProductPage.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
