import { Product } from '@/models/Product';
import { StoreModel } from '@/models/Store';
import { AxiosError, AxiosResponse } from 'axios';
import { createStore } from 'vuex';
import categoryService from '../core/services/CategoryService';
import fileService from '../core/services/FileService';
import productService from '../core/services/ProductService';
import storeService from '../core/services/StoreService';
import { ApiResponse } from './../models/ApiResponse';
import { Category } from './../models/Category';

export default createStore({
  state: {
    storeModel: new StoreModel(),
    categories: [] as Category[],
    products: [] as Product[]
  },
  getters: {
  },
  mutations: {
    SAVE_STORE (state, data) {
      state.storeModel = data;
      // localStorage.setItem(STORE, JSON.stringify(data))
      // localStorage.setItem(ID, JSON.stringify(data))
      storeService.store = data
    },
    SAVE_CATEGORIES (state, categories) {
      state.categories = categories;
      // localStorage.setItem(CATEGORIES, JSON.stringify(categories))
    },
    SAVE_PRODUCTS (state, products) {
      state.products = products;
      // localStorage.setItem(PRODUCTS, JSON.stringify(products))
    }
  },
  actions: {
    async loadStore ({ commit }) {
      await storeService.getAll().then((result: AxiosResponse<ApiResponse>) => {
        commit('SAVE_STORE', result.data.data);
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    updateStoreContact ({ commit }, data: StoreModel) {
      storeService.updateContact(data).then((result: AxiosResponse<ApiResponse>) => {
        // localStorage.setItem(STORE, JSON.stringify(data))
        // commit('SAVE_STORE', result.data.data);
        alert('Thành công!')
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    updateStoreInfo ({ commit }, data: StoreModel) {
      storeService.updateInfo(data).then((result: AxiosResponse<ApiResponse>) => {
        // localStorage.setItem(STORE, JSON.stringify(data))
        // commit('SAVE_STORE', result.data.data);
        // alert('Thành công!')
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    loadCategories ({ commit }) {
      categoryService.getAll().then((result: AxiosResponse<ApiResponse>) => {
        commit('SAVE_CATEGORIES', result.data.data);
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    createCategories ({ commit }, data: Category) {
      fileService.create(data.file!).then((result: AxiosResponse<ApiResponse>) => {
        data.imageUrl = result.data.data.insertedId

        categoryService.create(data).then((result: AxiosResponse<ApiResponse>) => {
          data._id = result.data.data.insertedId
          this.state.categories.push(data)
          commit('SAVE_CATEGORIES', this.state.categories);
          alert('Thành công!')
        }).catch((error: AxiosError) => {
          throw new Error(`API ${error}`);
        });
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    async updateCategories ({ commit }, data: Category) {
      let res = null
      if (data.imageUrl) {
        await fileService.delete(data.imageUrl)
      }
      if (data.file) {
        res = await fileService.create(data.file!)
        data.imageUrl = res.data.data.insertedId
      }
      categoryService.update(data).then((result: AxiosResponse<ApiResponse>) => {
        const index = this.state.categories.findIndex(f => f._id === data._id)
        this.state.categories.splice(index, 1, data)
        commit('SAVE_CATEGORIES', this.state.categories);
        alert('Thành công!')
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    deleteCategories ({ commit }, id: string) {
      categoryService.delete(id).then((result: AxiosResponse<ApiResponse>) => {
        const categories = this.state.categories.filter(f => f._id !== id)
        commit('SAVE_CATEGORIES', categories);
        alert('Thành công!')
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    loadProducts ({ commit }) {
      productService.getAll().then((result: AxiosResponse<ApiResponse>) => {
        commit('SAVE_PRODUCTS', result.data.data);
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    loadProductsByCategoryId ({ commit }, categoryId: string) {
      productService.getAll().then((result: AxiosResponse<ApiResponse>) => {
        let products: Product[] = result.data.data
        if (categoryId !== '0') {
          products = products.filter(f => f.categoryId === categoryId)
        }
        commit('SAVE_PRODUCTS', products);
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    searchProducts ({ commit }, name: string) {
      productService.getAll().then((result: AxiosResponse<ApiResponse>) => {
        let products: Product[] = result.data.data
        products = products.filter(f => f.name.includes(name))
        commit('SAVE_PRODUCTS', products);
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    createProducts ({ commit }, data: Product) {
      fileService.create(data.file!).then((result: AxiosResponse<ApiResponse>) => {
        data.imageUrl = result.data.data.insertedId

        productService.create(data).then((result: AxiosResponse<ApiResponse>) => {
          data._id = result.data.data.insertedId
          this.state.products.push(data)
          commit('SAVE_PRODUCTS', this.state.products);
          alert('Thành công!')
        }).catch((error: AxiosError) => {
          throw new Error(`API ${error}`);
        });
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    },

    async updateProducts ({ commit }, data: Product) {
      if (data.imageUrl) {
        await fileService.delete(data.imageUrl)
      }
      if (data.file) {
        fileService.create(data.file!).then((result: AxiosResponse<ApiResponse>) => {
          data.imageUrl = result.data.data.insertedId
          productService.update(data).then((result: AxiosResponse<AxiosResponse>) => {
            const index = this.state.products.findIndex(f => f._id === data._id)
            this.state.products.splice(index, 1, data)
            commit('SAVE_PRODUCTS', this.state.products);
            alert('Thành công!')
          }).catch((error: AxiosError) => {
            throw new Error(`API ${error}`);
          });
        }).catch((error: AxiosError) => {
          throw new Error(`API ${error}`);
        });
      } else {
        productService.update(data).then((result: AxiosResponse<AxiosResponse>) => {
          const index = this.state.products.findIndex(f => f._id === data._id)
          this.state.products.splice(index, 1, data)
          commit('SAVE_PRODUCTS', this.state.products);
          alert('Thành công!')
        }).catch((error: AxiosError) => {
          throw new Error(`API ${error}`);
        });
      }
    },

    deleteProducts ({ commit }, id: string) {
      productService.delete(id).then((result: AxiosResponse<ApiResponse>) => {
        const products = this.state.products.filter(f => f._id !== id)
        commit('SAVE_PRODUCTS', products);
        alert('Thành công!')
      }).catch((error: AxiosError) => {
        throw new Error(`API ${error}`);
      });
    }
  },
  modules: {
  }
})
