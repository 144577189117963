import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type IConfig = AxiosRequestConfig & {
  showSpinner?: boolean;
};

const requestConfig: IConfig = {
  // baseURL: process.env.REACT_APP_PROD_API_URL,
  baseURL: 'https://qdbakeryapi.onrender.com/api',
  timeout: 1 * 60000,
  headers: {
    'Content-type': 'application/json',
    Authorization: '63aa74d99f082b8121935443'
  }
};

class HttpRequest {
  api: AxiosInstance;

  constructor () {
    this.api = axios.create(requestConfig);
    this.request()
    this.response()
  }

  request () {
    this.api.interceptors.request.use(function (config: AxiosRequestConfig) {
      // console.log('REQUEST | ' + config.baseURL + config.url, config.data);
      return config;
    }, function (error: AxiosError) {
      // console.log('error :>> ', error);
      return Promise.reject(error);
    });
  }

  response () {
    this.api.interceptors.response.use(function (response: AxiosResponse) {
      // console.log(response.status + ' | ' + response.config.baseURL + response.config.url, response.data);
      return response;
    }, function (error: AxiosError) {
      // console.log('error :>> ', error);
      // alert('Lỗi!')
      return Promise.reject(error);
    });
  }
}

export const http = new HttpRequest();
