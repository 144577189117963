
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import store from '../store';

export default defineComponent({
  name: 'NavbarComponent',
  computed: mapState({ getStoreName: () => store.state.storeModel?.name }),
  data: () => {
    return {
      isShow: false
    }
  },
  mounted () {
    store.dispatch('loadStore');
  },
  methods: {
    onChangeShow () {
      this.isShow = !this.isShow
    }
  }
})
